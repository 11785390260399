<template>
  <div class="modal is-active" v-if="productLinkups">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">Bulk set Contact Groups</span>
        <button class="delete" aria-label="close" v-on:click="close"></button>
      </header>
      <section class="modal-card-body">
        <p>
          By Selecting a contact group and saving. All these products will be updated to have the
          contact group assigned to them by product level. If these products are all the same destination
          and share the same contact. Consider setting the contact information at Destination level.
        </p>
        <br />
        <label class="label">Product Configs to update</label>
        <table class="table is-size-6 is-fullwidth" v-if="productLinkups.length > 0">
          <tr>
            <th>Name</th>
            <th>FourLetterCode</th>
            <th>Destination</th>
            <th>Current Contact Group</th>
          </tr>
          <tr v-for="d in productLinkups" :key="d.Id">
            <td>{{ d.Name }}</td>
            <td>{{ d.FourLetterCode }}</td>
            <td>{{ d.BatchDestination.Name }}</td>
            <td>
              <span
                v-if="getContactGroupById(d.ContactGroupId)"
              >{{ getContactGroupById(d.ContactGroupId).Name }}</span>
            </td>
          </tr>
        </table>
        <div class="field">
          <label class="label">Choose Contact Group</label>
          <div class="control">
            <div class="select">
              <select v-model="contactGroupIdToSet">
                <option :value="null">Please Select Contact Group...</option>
                <option v-for="c in contactGroups" :value="c.Id" :key="c.Id">
                  {{
                  c.Name
                  }}
                </option>
              </select>
            </div>&nbsp; &nbsp;
            <!-- <button class="button is-primary" v-on:click="addContactGroup">Create New</button> -->
          </div>
        </div>

        <contact-group-table :contact-group-id="contactGroupIdToSet"></contact-group-table>
      </section>

      <footer class="modal-card-foot">
        <b-button class="is-success" :loading="isLoading" v-on:click="saveChanges">Save</b-button>
        <button class="button" v-on:click="close">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  components: {
    contactGroupTable: () => import("./contactGroupTable.vue")
  },
  mixins: [baseMixin],
  props: {
    productLinkups: null,
    contactGroups: null
  },
  data() {
    return {
      isLoading: false,
      APIUrl: process.env.VUE_APP_APIURL,
      contactGroupIdToSet: null
    };
  },
  methods: {
    close() {
      //emit close!
      this.$emit("close");
    },
    getContactGroupById(id) {
      return this.contactGroups.filter(c => c.Id === id)[0];
    },
    saveChanges() {
      //what is nothing selected?
      if (this.contactGroupIdToSet == null) {
        this.$toast.open("unable to save as no contact group was selected");
      } else {
        this.axios.put(this.saveEndpoint).then(response => {
          //this.updateFulfilmentRecord(response.data);
          var msg =
            this.getSelectedContactGroup.Name +
            " has been selected as the contact group for the selected " +
            this.productLinkups.length +
            " products";

          this.$toast.open(msg);

          this.$emit("close", response.data);
        });
      }
    }

    // editContactGroupDialog(contactGroupId) {
    //   //load from the API
    //   this.isLoading = true;
    //   var url = this.APIUrl + "/contact-group/" + contactGroupId;
    //   this.axios.get(url).then(response => {
    //     this.EditContactGroup = response.data;
    //     this.isLoading = false;
    //   });
    //   //
    // },
    // addContactGroup() {
    //   this.EditContactGroup = {
    //     Id: null,
    //     Name: "",
    //     GroupType: 1,
    //     Contacts: []
    //   };
    // },

    // addContactGroupSuccess(contactGroup) {
    //   this.tempProductLinkup.ContactGroupId = contactGroup.Id;
    //   this.closeContactGroupDialog();
    // },
    // closeContactGroupDialog() {
    //   this.loadContactGroups(); //reload table
    //   this.contactGroupTableToBeUpdated = true;
    //   this.EditContactGroup = null;
    // }
  },
  computed: {
    getSelectedContactGroup() {
      return this.getContactGroupById(this.contactGroupIdToSet);
    },
    saveEndpoint() {
      var IdQueryString =
        "productIds=" +
        this.productLinkups
          .map(function(item) {
            return item.Id;
          })
          .join("&productIds=");

      var url =
        this.APIUrl +
        "/product/bulk/set-contact-group/" +
        this.contactGroupIdToSet +
        "?" +
        IdQueryString;
      return url;
    }
  }
};
</script>
